@font-face {
  font-family: 'GilRoy';
  src: url('./assets/Fonts/Gilroy-Regular.otf');
  font-weight: 400;
}
@font-face {
  font-family: 'GilRoy';
  src: url('./assets/Fonts/Gilroy-Bold.otf');
  font-weight: 700;
}
@font-face {
  font-family: 'GilRoy';
  src: url('./assets/Fonts/Radomir\ Tinkov\ -\ Gilroy-SemiBold.otf');
  font-weight: 600;
}